import React from 'react';

// Import styles
import styles from './PopupWindow.module.css';

// Import components
import HeadingOne from '../HeadingOne';
import ParagraphOne from '../ParagraphOne';
import BaseButton from '../BaseButton';

// Import images
import PopupImage from '../../assets/images/store.jpeg';

function PopupWindow() {
    document.body.style.overflow = 'hidden';

    const handleClosePopup = () => {
        document.getElementById("popupScreen").style.display = 'none';
        document.body.style.overflow = 'auto';
    }

    return ( 
        <div id='popupScreen' className={styles.background}>
            {/* Popup window */}
            <div className={styles.window}>
                {/* Left content (description) */}
                <div className={styles.leftSection}>
                    <div className={styles.leftSectionContent}>
                        <h1 className={styles.windowHeader}>Market Now Open!</h1>
                        <p className={styles.paragraph}>
                            We're thrilled to announce the opening of our new market! Explore a vibrant selection of authentic Mexican products, all sourced to bring the taste of Mexico to your kitchen. Don't miss out on exclusive products and special discounts! Come discover the ingredients that inspire our dishes!
                        </p>

                        {/* Pop up image (Mobile) */}
                        <div className={styles.mobileImageContainer}>
                            <img src={PopupImage} alt='Market'/>
                        </div>

                        <BaseButton additionalClassNames={styles.button} text='Continue' color={process.env.REACT_APP_RED} onClick={handleClosePopup}/>
                    </div>
                </div>

                {/* Right content (image) */}
                <div className={styles.rightSection}>
                    <div className={styles.imageContainer}>
                        <img src={PopupImage} alt='Market'/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PopupWindow;