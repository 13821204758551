import React from "react";
import { NavLink } from "react-router-dom";
import styles from './NavBar.module.css';

// Image imports

// Components
import NavBarDesktop from "./NavBarDesktop";
import NavBarMobile from "./NavBarMobile";

function Navbar() {

    // (Mobile) Collapse the navigation bar and scroll to top of the page upon page selection
    const handleClick = () => {
        var linkContainer = document.getElementById('links');
        linkContainer.style.display = "none";

        window.scrollTo({
            top: 0
        });
    };

    // (Mobile) Collapse the navigation bar and scroll to top of the page upon page selection and 
    // open email client
    const handleClickContact = () => {
        window.scrollTo({
            top: 0
        });

        window.location.href = "mailto:senoritasmex22@gmail.com";
    };

    return (
        <div>
            {/* ----- Desktop navigation bar -----  */}
            <NavBarDesktop />

            {/* -----  Mobile navigation bar -----  */}
            <NavBarMobile>
                <NavLink className={styles.navbarLink} onClick={handleClick} to="/">
                    Home
                </NavLink>

                <NavLink className={styles.navbarLink} onClick={handleClick} to="/about">
                    About
                </NavLink>

                <NavLink className={styles.navbarLink} onClick={handleClick} to="/menu">
                    Menu
                </NavLink>

                <NavLink className={styles.navbarLink} onClick={handleClick} to="/catering">
                    Catering
                </NavLink>

                <NavLink className={styles.navbarLink} onClick={handleClickContact} to="/">
                    Contact
                </NavLink>

                <NavLink className={styles.navbarLink} onClick={handleClick} to="/shop">
                    Shop
                </NavLink>
            </NavBarMobile>
        </div>
    );
}

export default Navbar;