import React from 'react';
// import styles from './index.module.css';

// Components
import Banner from '../components/Banner';

import HouseSpecials from '../components/HouseSpecials';
import SeasonalAndSpecials from '../components/SeasonalAndSpecials';
import BehindTheScenes from '../components/BehindTheScenes';
import CustomerREviews from '../components/CustomerReviews';
import MeetTheFamily from '../components/MeetTheFamily';
import DeliveryAvailable from '../components/DeliveryAvailable'

// Images and Videos

// Carousel
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader


function Home() {
    return (
        <div>
            {/* ----------------- Banner ----------------- */}
            <Banner />

            {/*----------- Best In House Section --------- */}
            <HouseSpecials />

            {/* ------- Seasonals and Specials ------------ */}
            <SeasonalAndSpecials />

            {/*-------- Behind the Scenes Section -------- */}
            <BehindTheScenes />

            {/* ---------- Customer Reviews ---------------- */}
            <CustomerREviews />

            {/*----------- Meet the Family ----------------- */}
            <MeetTheFamily />

            {/* -------- Delivery Available ---------------- */}
            <DeliveryAvailable />

        </div>
    );
}

export default Home;